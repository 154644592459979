export interface IEnv {
  NEXT_PUBLIC_DEBUG_FLAG: boolean;
  NEXT_PUBLIC_API_BASE_BFF: string;
  NEXT_PUBLIC_API_BASE_YENTA: string;
  NEXT_PUBLIC_API_BASE_WANDERER: string;
  NEXT_PUBLIC_API_BASE_ARTEMIS: string;
  NEXT_PUBLIC_HTTP_BASIC_AUTH_USERNAME: string;
  NEXT_PUBLIC_HTTP_BASIC_AUTH_PASSWORD: string;
  NEXT_PUBLIC_YENTA_IMAGE_BASE_URL: string;
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: string;
  NEXT_PUBLIC_ARTEMIS_API_KEY: string;
  NEXT_PUBLIC_CF_SPACE_ID: string;
  NEXT_PUBLIC_CF_ACCESS_TOKEN: string;
  NEXT_PUBLIC_MORTGAGE_CALCULATOR_WIDGET_KEY: string;
  NEXT_PUBLIC_BOLT_DOMAIN: string;
  NEXT_PUBLIC_JOINREAL_DOMAIN: string;
  NEXT_PUBLIC_EMAIL_ADDRESS: string;
  NEXT_PUBLIC_REAL_TITLE_DOMAIN: string;
  NEXT_PUBLIC_INVESTORS_DOMAIN: string;
  NEXT_PUBLIC_GA_TRACKING_ID: string;
  NEXT_PUBLIC_NEWSROOM_SUBDOMAIN: string;
  NEXT_PUBLIC_ONEREAL_DOMAIN: string;
  NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN: string;
  NEXT_PUBLIC_TITLE_SUBDOMAIN: string;
  NEXT_PUBLIC_MORTGAGE_SUBDOMAIN: string;
  NEXT_PUBLIC_JOIN_SUBDOMAIN: string;
  NEXT_PUBLIC_ONEREAL_BLOG_SUBDOMAIN: string;
  NEXT_PUBLIC_ONEREAL_EVENT_SUBDOMAIN: string;
  NEXT_PUBLIC_ONEREAL_WP_ENGINE_SUBDOMAIN: string;
  NEXT_PUBLIC_SUPPORTED_COUNTRIES: string;
  NEXT_PUBLIC_US_MILITARY_DIVISION: string;
  NEXT_PUBLIC_CA_MILITARY_DIVISION: string;
  NEXT_PUBLIC_US_LUXURY_DIVISION: string;
  NEXT_PUBLIC_REAL_BUYER_PLAYBOOK_DOMAIN: string;
  NEXT_PUBLIC_ONEREAL_WIDENCOLLECTIVE_SUBDOMAIN: string;
  NEXT_PUBLIC_ONEREAL_TITLE_APPLY_FORM_DOMAIN: string;
  NEXT_PUBLIC_MYHOME_MORTGAGE_SUBDOMAIN: string;
  NEXT_PUBLIC_REAL_TITLE_CAPTURE: string;
}

const env: IEnv = {
  NEXT_PUBLIC_DEBUG_FLAG: process.env.NEXT_PUBLIC_DEBUG_FLAG === 'true',
  NEXT_PUBLIC_API_BASE_BFF: process.env.NEXT_PUBLIC_API_BASE_BFF!,
  NEXT_PUBLIC_API_BASE_YENTA: process.env.NEXT_PUBLIC_API_BASE_YENTA!,
  NEXT_PUBLIC_API_BASE_WANDERER: process.env.NEXT_PUBLIC_API_BASE_WANDERER!,
  NEXT_PUBLIC_API_BASE_ARTEMIS: process.env.NEXT_PUBLIC_API_BASE_ARTEMIS!,
  NEXT_PUBLIC_HTTP_BASIC_AUTH_USERNAME:
    process.env.NEXT_PUBLIC_HTTP_BASIC_AUTH_USERNAME!,
  NEXT_PUBLIC_HTTP_BASIC_AUTH_PASSWORD:
    process.env.NEXT_PUBLIC_HTTP_BASIC_AUTH_PASSWORD!,
  NEXT_PUBLIC_YENTA_IMAGE_BASE_URL:
    process.env.NEXT_PUBLIC_YENTA_IMAGE_BASE_URL!,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
  NEXT_PUBLIC_ARTEMIS_API_KEY: process.env.NEXT_PUBLIC_ARTEMIS_API_KEY!,
  NEXT_PUBLIC_CF_SPACE_ID: process.env.NEXT_PUBLIC_CF_SPACE_ID!,
  NEXT_PUBLIC_CF_ACCESS_TOKEN: process.env.NEXT_PUBLIC_CF_ACCESS_TOKEN!,
  NEXT_PUBLIC_MORTGAGE_CALCULATOR_WIDGET_KEY:
    process.env.NEXT_PUBLIC_MORTGAGE_CALCULATOR_WIDGET_KEY!,
  NEXT_PUBLIC_BOLT_DOMAIN: process.env.NEXT_PUBLIC_BOLT_DOMAIN!,
  NEXT_PUBLIC_JOINREAL_DOMAIN: process.env.NEXT_PUBLIC_JOINREAL_DOMAIN!,
  NEXT_PUBLIC_EMAIL_ADDRESS: process.env.NEXT_PUBLIC_EMAIL_ADDRESS!,
  NEXT_PUBLIC_REAL_TITLE_DOMAIN: process.env.NEXT_PUBLIC_REAL_TITLE_DOMAIN!,
  NEXT_PUBLIC_INVESTORS_DOMAIN: process.env.NEXT_PUBLIC_INVESTORS_DOMAIN!,
  NEXT_PUBLIC_GA_TRACKING_ID: process.env.NEXT_PUBLIC_GA_TRACKING_ID!,
  NEXT_PUBLIC_NEWSROOM_SUBDOMAIN: process.env.NEXT_PUBLIC_NEWSROOM_SUBDOMAIN!,
  NEXT_PUBLIC_ONEREAL_DOMAIN: process.env.NEXT_PUBLIC_ONEREAL_DOMAIN!,
  NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN:
    process.env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN!,
  NEXT_PUBLIC_TITLE_SUBDOMAIN: process.env.NEXT_PUBLIC_TITLE_SUBDOMAIN!,
  NEXT_PUBLIC_MORTGAGE_SUBDOMAIN: process.env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN!,
  NEXT_PUBLIC_JOIN_SUBDOMAIN: process.env.NEXT_PUBLIC_JOIN_SUBDOMAIN!,
  NEXT_PUBLIC_ONEREAL_BLOG_SUBDOMAIN:
    process.env.NEXT_PUBLIC_ONEREAL_BLOG_SUBDOMAIN!,
  NEXT_PUBLIC_ONEREAL_EVENT_SUBDOMAIN:
    process.env.NEXT_PUBLIC_ONEREAL_EVENT_SUBDOMAIN!,
  NEXT_PUBLIC_ONEREAL_WP_ENGINE_SUBDOMAIN:
    process.env.NEXT_PUBLIC_ONEREAL_WP_ENGINE_SUBDOMAIN!,
  NEXT_PUBLIC_SUPPORTED_COUNTRIES: process.env.NEXT_PUBLIC_SUPPORTED_COUNTRIES!,
  NEXT_PUBLIC_US_MILITARY_DIVISION:
    process.env.NEXT_PUBLIC_US_MILITARY_DIVISION!,
  NEXT_PUBLIC_CA_MILITARY_DIVISION:
    process.env.NEXT_PUBLIC_CA_MILITARY_DIVISION!,
  NEXT_PUBLIC_US_LUXURY_DIVISION: process.env.NEXT_PUBLIC_US_LUXURY_DIVISION!,
  NEXT_PUBLIC_REAL_BUYER_PLAYBOOK_DOMAIN:
    process.env.NEXT_PUBLIC_REAL_BUYER_PLAYBOOK_DOMAIN!,
  NEXT_PUBLIC_ONEREAL_WIDENCOLLECTIVE_SUBDOMAIN:
    process.env.NEXT_PUBLIC_ONEREAL_WIDENCOLLECTIVE_SUBDOMAIN!,
  NEXT_PUBLIC_ONEREAL_TITLE_APPLY_FORM_DOMAIN:
    process.env.NEXT_PUBLIC_ONEREAL_TITLE_APPLY_FORM_DOMAIN!,
  NEXT_PUBLIC_MYHOME_MORTGAGE_SUBDOMAIN:
    process.env.NEXT_PUBLIC_MYHOME_MORTGAGE_SUBDOMAIN!,
  NEXT_PUBLIC_REAL_TITLE_CAPTURE: process.env.NEXT_PUBLIC_REAL_TITLE_CAPTURE!,
};

export default env;
