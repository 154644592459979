import { DateTime } from 'luxon';
import Link from 'next/link';
import React from 'react';
import { OfficesResponse } from '../../openapi/bff';
import { LegaleseElement } from '../../openapi/wanderer';
import { TypeMarketsCountrySection } from '../../types/generated/page-contents';
import {
  BrokerLicense,
  RealApiAgentWebsiteResponse,
} from '../../types/real-api-types';
import { isCASiteOnly } from '../../utils/SiteConfiguration';
import GeminiCAFooter from './GeminiCAFooter';
import GeminiUSFooter from './GeminiUSFooter';

interface GeminiFooterDisclaimerProps {
  disclaimers?: LegaleseElement[];
  brokerLicenses: BrokerLicense[];
  countriesWithStates: TypeMarketsCountrySection[];
  agent?: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
}

const GeminiFooterDisclaimer: React.FC<GeminiFooterDisclaimerProps> = ({
  countriesWithStates,
  brokerLicenses,
  disclaimers,
  agent,
  allOffices,
}) => {
  const isCASite = isCASiteOnly();

  return (
    <>
      <article className='lg:px-[70px] px-5 max-w-[1440px] mx-auto flex xl:flex-row flex-col xl:justify-between xl:items-center font-inter font-normal xl:text-[13px] xl:leading-[19.5px] text-sm text-off-white xl:py-5 py-6'>
        <section className='hidden xl:block order-1'>
          <span>{`© ${
            DateTime.local().year
          } The Real Brokerage. All Rights Reserved.`}</span>
        </section>
        <section className='flex xl:flex-row flex-col gap-6 order-1 xl:order-2'>
          <Link href='/pages/privacy-policy/'>Privacy Policy</Link>
          <Link href='/pages/terms-of-use/'>Terms of Use</Link>
          <Link href='/pages/state-licenses/'>State Licenses</Link>
          <Link href='/pages/data-policy/'>Data Processing Policy</Link>
          <section className='block xl:hidden'>
            <p>{`© ${
              DateTime.local().year
            } The Real Brokerage. All Rights Reserved.`}</p>
          </section>
          <Link href='https://www.takeoffnyc.com/' target='_blank'>
            Site by Takeoff
          </Link>
        </section>
      </article>
      <article className='lg:px-[70px] px-5 max-w-[1440px] mx-auto'>
        {isCASite ? (
          <GeminiCAFooter />
        ) : (
          <GeminiUSFooter
            countriesWithStates={countriesWithStates}
            brokerLicenses={brokerLicenses}
            disclaimers={disclaimers}
            allOffices={allOffices}
            agent={agent}
          />
        )}
      </article>
    </>
  );
};

export default GeminiFooterDisclaimer;
