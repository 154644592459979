/* eslint-disable @next/next/no-img-element */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import env from '../../utils/env';
import { isCASiteOnly } from '../../utils/SiteConfiguration';
import { SocialMediaLink } from '../../utils/SocialLinkUtils';
import LinkWithAnchor from '../LinkWithAnchor';
import GeminiButton from './GeminiButton';

interface GeminiSocialLinksProps {
  isCanadaOnly: boolean;
  socials: SocialMediaLink[];
}

const GeminiSocialLinks: React.FC<GeminiSocialLinksProps> = ({
  isCanadaOnly,
  socials,
}) => {
  const isCASite = isCASiteOnly();

  return (
    <section className='flex flex-col xl:gap-7 items-start sm:col-span-1 sm:justify-between xl:border-r border-new-gray border-opacity-40 py-4 xl:px-[70px] xl:pb-5 xl:min-h-[333px] gap-[40px] border-b pb-[40px] px-5 xl:border-b-transparent'>
      <div>
        <img
          src='/img/real-white-logo.svg'
          alt='real-logo'
          width={130}
          height={59}
        />
        <div className='xl:block hidden mt-[34px]'>
          <GeminiButton
            label={
              isCanadaOnly ? 'Take Me To Real US' : 'Take Me To Real Canada'
            }
            variant='secondary'
            href={
              isCASite
                ? `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}`
                : `${env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN}`
            }
          />
        </div>
      </div>

      {!!socials && (
        <div className='flex items-center xl:gap-[25px] gap-[30px]'>
          {(socials || []).map((social) => (
            <div
              key={social?.name}
              className='h-5 w-5 flex items-center justify-center'
            >
              <LinkWithAnchor href={social?.url} target='_blank'>
                <FontAwesomeIcon
                  icon={social?.icon}
                  className='text-new-primary'
                />
              </LinkWithAnchor>
            </div>
          ))}
        </div>
      )}
      <div className='xl:hidden block'>
        <GeminiButton
          label={isCanadaOnly ? 'Take Me To Real US' : 'Take Me To Real Canada'}
          variant='secondary'
          href={
            isCASite
              ? `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}`
              : `${env.NEXT_PUBLIC_ONEREAL_CANADA_DOMAIN}`
          }
        />
      </div>
    </section>
  );
};

export default GeminiSocialLinks;
